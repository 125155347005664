<template>
  <div>
    <page-title :heading="$t('accounting.lang_nav_collectivebill')" :subheading="$t('erp.lang_nav_faktura')"
                :icon=icon></page-title>

    <div class="app-main__inner">
      <v-card>
        <daily-collective-bill-list-component/>
      </v-card>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import DailyCollectiveBillListComponent from "@/components/billing/collectivebill/DailyCollectiveBillListComponent";

export default {
  name: "DailyCollectiveBillView",
  components: {DailyCollectiveBillListComponent, PageTitle},

  data: () => ({
    icon: 'pe-7s-folder icon-gradient bg-tempting-azure',
  })
}
</script>
